<template>
  <div style="margin-top: 140px">
      <button @click="print">imprimir</button>
    <div id="presupuesto">
      <div >
        <h3 class="mt-5">PRESUPUESTO:</h3>
        <br />
        <!-- {{ dataClient }} -->
        <div class="row">
          <div class="offset-md-4 col-md-4">
            <h5>Nombre: {{ dataClient.nombre }}</h5>
            <h5>Numero Whatsaap: {{ dataClient.whatsapp }}</h5>
            <h5>Email: {{ dataClient.email }}</h5>
          </div>
        </div>
      </div>

      <hr />
      <!-- {{dataWizard}} -->
      <div v-if="dataWizard" class="container">
        <div class="row" v-for="item of dataWizard" :key="item.id">
          <div class="col-md-4 text-left">
            {{ item.title ? item.title : "" }}
          </div>
          <div class="col-md-4 text-left">{{ item.description }}</div>
          <div class="col-md-1 text-right">$</div>
          <div class="col-md-1 text-right">{{ item.price }}</div>
        </div>
      </div>
      <hr />
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-4 text-left"></div>
          <div class="col-md-4 text-right">Total:</div>
          <div class="col-md-1 text-right">$</div>
          <div class="col-md-1 text-right">{{ total }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";

export default {
  data() {
    return {
      dataWizard: [{title: '', description: '', price: 0}],
      dataClient: {},
      total: 0
    };
  },
  async mounted() {
    try {
        let req = await JSON.parse(localStorage.getItem("pdf"));
        if (req.length>0){
            setTimeout(async () => {
                this.dataWizard = req
                this.dataClient = await this.dataWizard[12];
                let total = 0
                for await (let item of this.dataWizard){
                    if(item.price>0){
                        total = total + Number(item.price)
                        console.log('price ', item.price)
                    }
                }
                console.log('------>', this.dataWizard);
                // alert(total)
                this.total = total
            }, 2000);
        }
    } catch (error) {
        console.log('error', error)
    }
  },
  methods: {
    print(){
        // var pdf = new jsPDF();
        // var element = document.getElementById('presupuesto');
        // var width= element.style.width;
        // var height = element.style.height;
        // html2canvas(element).then(canvas => {
        //     var image = canvas.toDataURL('image/png');
        //     pdf.addImage(image, 'JPEG', 15, 40, width, height);
        //     pdf.save('presupuesto.pdf')
        // });
    
        // var pdf = new jsPDF({unit: 'mm', format: 'a4', orientation: 'portrait' })
        // pdf.html(document.getElementById('presupuesto'), {
        //     callback: function (pdf) {
        //     pdf.save('presupuesto.pdf');
        //     }
        // })
        window.print();  

      }
  },
};
</script>

<style>
</style>